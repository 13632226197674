<template>
	<div id="root">
		<div class="todo-container">
			<div class="todo-wrap">
				<!-- header -->
				<TodoHeader @addTodo="addTodo"></TodoHeader>
				<!-- list -->
				<TodoList :todos="todos"></TodoList>
				<!-- footer -->
				<TodoFooter :todos="todos" @checkAllTodo="checkAllTodo" @clearAllTodo="clearAllTodo"></TodoFooter>
			</div>
		</div>
	</div>
</template>

<script>
	import TodoHeader from "./components/TodoHeader.vue";
	import TodoList from "./components/TodoList.vue";
	import TodoFooter from "./components/TodoFooter.vue";

	export default {
		name: "App",
		mounted() {
			this.$bus.$on("checkTodo", this.checkTodo);
			this.$bus.$on("deleteTodo", this.deleteTodo);
			this.$bus.$on("updateTodo", this.updateTodo);
		},
		data() {
			return {
				todos: JSON.parse(localStorage.getItem("todos")) || [],
			};
		},
		methods: {
			addTodo(todoObj) {
				// console.log(todoObj);
				this.todos.unshift(todoObj);
			},
			// 数据在哪，对数据的操作也在哪
			// 勾选or取消勾选一个todo
			checkTodo(id) {
				// 遍历找到对应id的todo
				this.todos.forEach((todo) => {
					if (todo.id === id) {
						todo.done = !todo.done;
					}
				});
			},
			//更新一个todo
			updateTodo(id, title) {
				this.todos.forEach((todo) => {
					if (todo.id === id) {
						todo.title = title;
					}
				});
			},
			// 删除todo
			deleteTodo(id) {
				// filter不改变原数组，需要重新给数组赋值
				this.todos = this.todos.filter((todo) => todo.id !== id);
			},
			//全选框
			checkAllTodo(done) {
				this.todos.map((todo) => (todo.done = done));
			},
			// 清除所有已经完成的todo
			clearAllTodo() {
				this.todos = this.todos.filter((todo) => !todo.done);
			},
		},
		components: {
			TodoHeader,
			TodoList,
			TodoFooter,
		},
		watch: {
			todos: {
				deep: true,
				handler(value) {
					localStorage.setItem("todos", JSON.stringify(value));
				},
			},
		},
		beforeDestroy() {
			this.$bus.$off("checkTodo");
			this.$bus.$off("deleteTodo");
			this.$bus.$off("updateTodo");
		},
	};
</script>

<style>
/*base*/
body {
	background: #fff;
}

.btn {
	display: inline-block;
	padding: 4px 12px;
	margin-bottom: 0;
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	vertical-align: middle;
	cursor: pointer;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2),
		0 1px 2px rgba(0, 0, 0, 0.05);
	border-radius: 4px;
}

.btn-danger {
	color: #fff;
	background-color: #da4f49;
	border: 1px solid #bd362f;
}

.btn-danger:hover {
	color: #fff;
	background-color: #bd362f;
}

.btn-normal {
	color: #fff;
	background-color: rgb(100, 188, 223);
	border: 1px solid rgb(63, 179, 224);
}

.btn-normal:hover {
	color: #fff;
	background-color: rgb(63, 179, 224);
}

.btn:focus {
	outline: none;
}

.todo-container {
	width: 600px;
	margin: 0 auto;
}
.todo-container .todo-wrap {
	padding: 10px;
	border: 1px solid #ddd;
	border-radius: 5px;
}
</style>
